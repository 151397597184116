import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import fetch from 'node-fetch'

import { config, sanitize } from '@fairhq/common'

import { getDefaultHeaders } from './getDefaultHeaders'

const { baseUrl } = config

const getHeadersWithJSON = () => {
  const headers = {
    ...getDefaultHeaders(),
    'Content-Type': 'application/json',
  }

  return headers
}

export const handleError = async (response: any) => {
  if (response.status >= 400 && response.status < 600) {
    const { message } = await response.json()
    let error = { name: response.statusText, message: response.statusText }
    if (_.isArray(message)) {
      const firstMessage = message?.[0]?.messages?.[0]
      error = {
        name: firstMessage?.id || error.name,
        message: firstMessage?.message || error.name,
      } as Error
    }
    throw error
  }
  return response
}

export const http = {
  get(url: string, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'GET',
      headers: { ...getHeadersWithJSON(), ...headers },
    }).then(handleError)
  },
  getWithAxios(url: string, options: AxiosRequestConfig = {}) {
    return axios.get(`${baseUrl}${url}`, {
      ...options,
      headers: { ...getDefaultHeaders(), ...options.headers } as any,
    })
  },
  post(url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'POST',
      headers: { ...getHeadersWithJSON(), ...headers },
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  postWithAxios(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.post(`${baseUrl}${url}`, data, {
      ...options,
      headers: { ...getDefaultHeaders(), ...options.headers } as any,
    })
  },
  put(url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'PUT',
      headers: { ...getHeadersWithJSON(), ...headers },
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  putWithAxios(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.put(`${baseUrl}${url}`, data, {
      ...options,
      headers: { ...getDefaultHeaders(), ...options.headers } as any,
    })
  },
  patch(url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'PATCH',
      headers: { ...getHeadersWithJSON(), ...headers },
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  delete(url: string) {
    return fetch(`${baseUrl}${url}`, {
      method: 'DELETE',
      headers: getHeadersWithJSON(),
    }).then(handleError)
  },
}
