import { shallowEqual } from 'react-redux'

import { NumberInput } from '@fairhq/common'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionType } from 'store/questionSession/types'
import { State } from 'store/state'

import { Binary } from './Binary'
import { Dropdown } from './Dropdown'
import { Scale } from './Scale'
import { Semantic } from './Semantic'
import { SingleOrMultipleChoice } from './SingleOrMultipleChoice'

export const QuestionTypeCard = () => {
  const type = useAppSelector(
    (state: State) => state.questionSessionReducer.question?.type,
    shallowEqual
  )

  const dispatch = useAppDispatch()
  const { question, value } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      value: state.questionSessionReducer.value,
    }),
    shallowEqual
  )
  const { title } = question ?? {}

  if (type === QuestionType.BINARY) {
    return <Binary />
  }
  if (type === QuestionType.DROPDOWM) {
    return <Dropdown />
  }
  if (type === QuestionType.SEMANTIC) {
    return <Semantic />
  }
  if (type === QuestionType.TEXT_NUMBER) {
    return (
      <NumberInput
        onChange={newValue => {
          dispatch(questionSessionActions.setValue(newValue))
        }}
        value={value}
        placeholder={title}
      />
    )
  }
  if (type?.startsWith('scale')) {
    return <Scale is10PointScale={type === QuestionType.SCALE_10} />
  }

  return <SingleOrMultipleChoice multiple={type === QuestionType.MULTI} />
}
