import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export const useAreaProgress = (): {
  submitted: number
  completed: number
  skipped: number
  total: number
  disabled: boolean
  rejected: number
  reviewed: number
  loading?: boolean
  score?: number
} => {
  const { currentIndex, questions } = useAppSelector(
    (state: State) => ({
      currentIndex: state.questionSessionReducer.currentIndex,
      questions: state.questionSessionReducer.questions ?? [],
    }),
    shallowEqual
  )

  return {
    disabled: questions.length - 1 >= currentIndex,
    total: questions.length,
    skipped: 0,
    completed: currentIndex,
    reviewed: 0,
    rejected: 0,
    submitted: 0,
  }
}
