import { configureStore, EnhancedStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import logger from 'redux-logger'

import { reducer } from './reducer'

const createStore = (): EnhancedStore => {
  const middleware = (getDefaultMiddleware: any) => {
    const defaultMiddleware = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })

    if (
      process.env.NODE_ENV !== 'production' &&
      !window.localStorage.getItem('fairhq-disable-redux-logger')
    ) {
      defaultMiddleware.concat(logger)
    }

    return defaultMiddleware
  }

  return configureStore({
    reducer,
    middleware,
  })
}

export const store = createStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
