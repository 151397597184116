import { FunctionComponent, Suspense } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import { Container } from 'rsuite'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ThemeProvider as ThemeUIThemeProvider } from 'theme-ui'

import { theme, themeUI } from '@fairhq/common'
import Loading from 'components/loading'
import { Routes } from 'Routes'

const GlobalStyle = createGlobalStyle`
  body {
    font-feature-settings: "liga", "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Druk Wide Web', sans-serif;
  }

  a {
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
`

const BodyContainer = styled(Container)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
`

const AppContainer = styled(Container)`
  overflow: auto;
  z-index: 1;
  flex: 1;

  .rs-content {
    height: 100%;
  }

  color: ${({ theme: styledComponentsTheme }) =>
    styledComponentsTheme.colors.grey500};
  background-color: ${({ theme: styledComponentsTheme }) =>
    styledComponentsTheme.colors.white};
`

const App: FunctionComponent = () => (
  <Suspense fallback={<Loading backdrop vertical />}>
    <Router>
      <ThemeUIThemeProvider theme={themeUI}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <BodyContainer>
            <AppContainer className="fairhq-app">
              <Routes />
            </AppContainer>
          </BodyContainer>
        </ThemeProvider>
      </ThemeUIThemeProvider>
    </Router>
  </Suspense>
)

export default App
