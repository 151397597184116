import { FunctionComponent, ReactNode } from 'react'

import { Loader } from 'rsuite'

const Loading: FunctionComponent<{
  backdrop?: boolean
  center?: boolean
  content?: ReactNode
  size?: 'lg' | 'md' | 'sm' | 'xs'
  vertical?: boolean
}> = ({ backdrop, center, content, size, vertical }) => (
  <Loader
    center={center}
    content={content}
    backdrop={backdrop}
    vertical={vertical}
    size={size || (backdrop && 'lg') || 'md'}
    speed="slow"
  />
)

export default Loading
