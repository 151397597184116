import { isEqual, isEmpty } from 'lodash'

import { Assessment } from 'store/assessment/types'

export const checkIfAnswerHasChanged = (
  answer: Assessment,
  answerToSave: Partial<Assessment>
) =>
  (!isEmpty(answerToSave.assessmentOptions) &&
    !isEqual(
      answer.assessmentOptions
        .filter(({ optionId }) => optionId)
        .map(({ optionId }) => +optionId),
      answerToSave.assessmentOptions?.map(({ optionId }) => +optionId)
    )) ||
  (answerToSave.value && !isEqual(answer.value, answerToSave.value)) ||
  (answerToSave.other && !isEqual(answer.other, answerToSave.other))
