import { Flex } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'
import { Animation, Container } from 'rsuite'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import Loading from 'components/loading'
import { useAppSelector } from 'store/hooks'
import { QuestionType } from 'store/questionSession/types'
import { State } from 'store/state'

import { Subtitle } from '../../../styled'
import { Message } from '../../../styled/message'

import { QuestionTypeCard } from './QuestionTypeCard'

const QuestionTypeCardContainer = styled(Container)`
  width: 100%;
  flex: initial;

  .rs-radio-group-picker .rs-radio-checker > label,
  .rs-radio-group-picker .rs-radio-checker > label:hover,
  .rs-radio-group-picker .rs-radio-checker > label:focus {
    color: ${({ theme }) => theme.colors.grey500};
  }
`

const QuestionCardHeaderContainer = styled.div<{ type?: string }>`
  padding-top: ${({ type }) =>
    type === QuestionType.SINGLE || type === QuestionType.MULTI ? '80px' : '0'};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;

  p {
    text-align: center;
    justify-content: center;
  }
`

const QuestionCardContainer = styled.div<{ type?: string }>`
  max-width: 800px;
  margin: 0 auto 20px;
  align-items: center;
  min-height: ${({ type }) =>
    type === QuestionType.SINGLE || type === QuestionType.MULTI
      ? '400px'
      : 'auto'};
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${Subtitle} {
    margin-bottom: 8px;
    text-align: center;
    justify-content: center;
  }

  ${Message} {
    text-align: center;
    &[type='indication'] {
      margin-top: 18px;
      margin-bottom: 0;
    }
  }
`

export const QuestionCard = () => {
  const { t } = useTranslation()
  const { questionIndex, question, skipped } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      questionIndex: state.questionSessionReducer.currentIndex + 1,
      skipped: state.questionSessionReducer.assessment?.skipped,
    }),
    shallowEqual
  )
  const { title, description } = question ?? {}
  const { type } = question ?? {}

  if (!type) {
    return <Loading backdrop vertical content={t('loading')} />
  }

  return (
    <Animation.Slide in placement="bottom">
      {(props, ref) => (
        <QuestionCardContainer
          type={type}
          ref={ref}
          {...props}
          key={question?.code}
        >
          <QuestionCardHeaderContainer type={type}>
            {skipped ? (
              <Flex
                as="p"
                sx={{
                  color: baseColors.purple800,
                  backgroundColor: baseColors.purple200,
                  padding: '4px 16px',
                  borderRadius: '30px',
                  display: 'inline-flex',
                  marginBottom: '18px',
                  strong: { marginRight: '5px' },
                }}
              >
                <Trans i18nKey="question.skipped" components={[<strong />]} />
              </Flex>
            ) : undefined}
            <Subtitle>
              {t('question.number', { number: questionIndex })}
            </Subtitle>
            <Message type="question">{title}</Message>
            {description && <Message type="indication">{description}</Message>}
          </QuestionCardHeaderContainer>
          <QuestionTypeCardContainer>
            <QuestionTypeCard />
          </QuestionTypeCardContainer>
        </QuestionCardContainer>
      )}
    </Animation.Slide>
  )
}
