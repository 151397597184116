import { Assessment } from 'store/assessment/types'

import { checkIfAnswerHasChanged } from './checkIfAnswerHasChanged'

export const shouldSaveAssessment = ({
  assessment,
  assessmentToSave,
}: {
  assessment?: Assessment
  assessmentToSave: Partial<Assessment>
}) =>
  (assessment?.id && checkIfAnswerHasChanged(assessment, assessmentToSave)) ||
  !assessment
