import { createSlice } from '@reduxjs/toolkit'
import { sortBy, isArray } from 'lodash'

import { isClearAccount } from '../helpers/isClearAccount'
import { isClearAll } from '../helpers/isClearAll'

import { getAnsweredOptionsCode } from './helpers/getAnsweredOptionsCode'
import { getQuestionWithSortedOptions } from './helpers/getQuestionWithSortedOptions'
import { getSkippedAndZeroValueQuestions } from './helpers/getSkippedAndZeroValueQuestions'
import { QuestionSessionState } from './types'

const initialState: QuestionSessionState = {
  other: '',
  selectedOptions: [],
  value: '',
  currentIndex: 0,
  currentAllIndex: 0,
  countAnswer: 0,
  hasNext: true,
  questions: [],
  savedAssessments: [],
}

const updateAssessment = (state: QuestionSessionState) => {
  state.assessment = state.savedAssessments?.find(
    assessment => assessment?.question?.id === state?.question?.id
  )
  const {
    other = '',
    value = '',
    assessmentOptions,
    question: assessmentQuestion,
  } = state.assessment ?? {}
  state.other = other
  state.value = value
  state.selectedOption =
    (assessmentQuestion?.type !== 'multi' &&
      assessmentOptions?.[0]?.option?.id) ||
    undefined

  const newValues =
    (assessmentQuestion?.type === 'multi' &&
      (assessmentOptions || [])
        ?.filter(({ option }) => option?.id)
        .map(({ option }) => option?.id)) ||
    []
  state.selectedOptions = isArray(newValues) ? newValues : []
}

const next = (state: QuestionSessionState) => {
  const stop = () => {
    state.hasNext = false
  }
  const answeredOptionsCode = getAnsweredOptionsCode(state.savedAssessments)
  const skippedAndZeroValueQuestions = getSkippedAndZeroValueQuestions(
    state.savedAssessments
  )
  state.currentIndex += 1
  let shouldBeCurrent = true
  do {
    state.currentAllIndex += 1
    if (state.currentAllIndex <= state.questions.length - 1) {
      const question = state.questions[state.currentAllIndex]
      shouldBeCurrent =
        (!!question?.linkedOptionsId?.find(option =>
          answeredOptionsCode?.includes(option)
        ) ||
          question?.linkedOptionsId?.length === 0) &&
        !question?.linkedQuestionsId?.find(airtableId =>
          skippedAndZeroValueQuestions?.includes(airtableId)
        )

      if (shouldBeCurrent) {
        state.question = getQuestionWithSortedOptions(question)
        updateAssessment(state)
      }
    } else {
      stop()
    }
  } while (!shouldBeCurrent && state.hasNext)
}

const questionSessionSlice = createSlice({
  name: 'question-session',
  initialState,
  reducers: {
    clear: () => initialState,
    saveAssessments(state, action) {
      if (action?.payload?.length > 0) {
        state.savedAssessments = action.payload
        updateAssessment(state)
      }
    },
    addAssessment(state, action) {
      state.savedAssessments = [
        ...(state.savedAssessments || []).filter(
          ({ id }: any) => +id !== +action.payload.id
        ),
        action.payload,
      ]
      state.assessment = action.payload
    },
    init(state) {
      state.question = state.questions?.[0]
      updateAssessment(state)
    },
    next,
    previous(state) {
      if (state.currentIndex > 0) {
        const answeredOptionsCode = getAnsweredOptionsCode(
          state.savedAssessments
        )
        const skippedAndZeroValueQuestions = getSkippedAndZeroValueQuestions(
          state.savedAssessments
        )
        state.currentIndex -= 1
        let shouldBeCurrent = true
        do {
          state.currentAllIndex -= 1
          if (state.currentAllIndex >= 0) {
            const question = state.questions[state.currentAllIndex]
            shouldBeCurrent =
              (!!question?.linkedOptionsId?.find(option =>
                answeredOptionsCode?.includes(option)
              ) ||
                question?.linkedOptionsId?.length === 0) &&
              !question?.linkedQuestionsId?.find(airtableId =>
                skippedAndZeroValueQuestions?.includes(airtableId)
              )

            if (shouldBeCurrent) {
              state.question = getQuestionWithSortedOptions(question)
              updateAssessment(state)
            }
          }
        } while (!shouldBeCurrent)

        state.hasNext = true
      }
    },
    setQuestionsAfterGet(state, action) {
      state.questions = sortBy(action.payload, ['priority'])
      const [firstQuestion] = state.questions
      if (firstQuestion) {
        state.question = getQuestionWithSortedOptions(firstQuestion)
      }
    },
    setOther(state, action) {
      state.other = action.payload
    },
    setValue(state, action) {
      state.value = action.payload
    },
    setSelectedOption(state, action) {
      state.selectedOption = Number(action.payload)
    },
    setSelectedOptions(state, action) {
      if (action.payload) {
        state.selectedOptions = action.payload
      }
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isClearAccount(), () => initialState)
  },
})

export const {
  actions: questionSessionActions,
  reducer: questionSessionReducer,
} = questionSessionSlice
