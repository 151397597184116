import { isArray } from 'lodash'

import { QuestionOption } from 'store/questionSession/types'

export const buildOptions = (
  options: QuestionOption[],
  selectedOption: number | number[] | undefined
) =>
  options.filter(({ id }) =>
    isArray(selectedOption)
      ? selectedOption.includes(id)
      : selectedOption === id
  )
