import _ from 'lodash'

import { shallowEqual } from 'react-redux'
import { Container, Radio, RadioGroup } from 'rsuite'
import styled from 'styled-components'

import { Label } from 'components/Label'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionOption } from 'store/questionSession/types'
import { State } from 'store/state'

const BinaryContainer = styled(Container)`
  .rs-radio-group {
    display: flex;

    .rs-radio {
      min-width: 258px;
    }
  }
`

export const Binary = () => {
  const dispatch = useAppDispatch()
  const { question, selectedOption } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      selectedOption: state.questionSessionReducer.selectedOption,
    }),
    shallowEqual
  )
  const { options } = question ?? {}
  const toRadioOption = ({ id, code, title }: QuestionOption) => (
    <Radio key={code} value={id}>
      <Label label={title} checked={id === selectedOption} />
    </Radio>
  )

  return (
    <BinaryContainer>
      <RadioGroup
        onChange={value => {
          dispatch(questionSessionActions.setSelectedOption(value))
        }}
        value={selectedOption}
        appearance="picker"
      >
        {_.sortBy(options, ['priority'])?.map(toRadioOption)}
      </RadioGroup>
    </BinaryContainer>
  )
}
