import { http } from 'utils/service/http'

import { Assessment } from './types'

export const assessmentApi = {
  save(assessment: Partial<Assessment>) {
    return http.post(`assessments`, assessment)
  },
  update(id: string, assessment: Partial<Assessment>) {
    return http.patch(`assessments/${id}`, assessment)
  },
}
