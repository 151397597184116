import React, { FunctionComponent, PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

const fonts = {
  heading: "'Druk Wide Web', sans-serif",
  body: 'Poppins, sans-serif',
}

const sizes = {
  '2xs': { fontSize: '0.75rem', lineHeight: '1rem' },
  xs: { fontSize: '0.875rem', lineHeight: '1.25rem' },
  sm: { fontSize: '1rem', lineHeight: '1.5rem' },
  md: { fontSize: '1.125rem', lineHeight: '1.625rem' },
  lg: { fontSize: '1.25rem', lineHeight: '1.75rem' },
  xl: { fontSize: '1.5rem', lineHeight: '2rem' },
  '2xl': { fontSize: '2rem', lineHeight: '2.5rem' },
}

const StyledElement = styled.div`
  ${({ fontWeight, size, type, color }: TextProps) => css`
    font-weight: ${fontWeight};
    font-size: ${sizes[size].fontSize};
    line-height: ${sizes[size].lineHeight};
    font-family: ${fonts[type]};
    color: ${color};
  `}
`

export interface TextProps {
  className?: string
  element: React.ElementType
  type: 'heading' | 'body'
  size: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  fontWeight: 400 | 500 | 600 | 700
  color?: string
  htmlFor?: string
}

export const Text: FunctionComponent<PropsWithChildren<TextProps>> = ({
  children,
  className,
  element,
  type,
  size,
  fontWeight,
  color,
  htmlFor,
}) => (
  <StyledElement
    as={element}
    className={className}
    htmlFor={htmlFor}
    type={type}
    size={size}
    fontWeight={fontWeight}
    color={color}
  >
    {children}
  </StyledElement>
)
