import React from 'react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { config } from '@fairhq/common'

import App from 'App'
import reportWebVitals from 'reportWebVitals'
import { store } from 'store/store'
import 'i18n'
import 'styles.css'

if (config.env !== 'local' && process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '785a00bbd1ea9d79bb52a0168235c351',
    plugins: [new BugsnagPluginReact()],
    appVersion: config.version,
    releaseStage: config.env,
    sendCode: true,
  })
}

const ErrorBoundary =
  config.env !== 'local' && process.env.NODE_ENV === 'production'
    ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment
    : React.Fragment

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
