export enum Colors {
  PURPLE = 'purple',
  PURPLE100 = 'purple100',
  PURPLE200 = 'purple200',
  PURPLE300 = 'purple300',
  PURPLE400 = 'purple400',
  PURPLE500 = 'purple500',
  PURPLE600 = 'purple600',
  PURPLE700 = 'purple700',
  PURPLE800 = 'purple800',
  PURPLE900 = 'purple900',
  YELLOW = 'yellow',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  PINK = 'pink',
  GREY500 = 'grey500',
  GREY400 = 'grey400',
  GREY300 = 'grey300',
  GREY200 = 'grey200',
  GREY200_2 = 'grey200_2',
  GREY100 = 'grey100',
  WHITE = 'white',
  BLACK = 'black',
  MODAL = 'modal',
  COOL_GREY_100 = 'coolGrey100',
  COOL_GREY_200 = 'coolGrey200',
  COOL_GREY_300 = 'coolGrey300',
  COOL_GREY_400 = 'coolGrey400',
  COOL_GREY_500 = 'coolGrey500',
  COOL_GREY_600 = 'coolGrey600',
  COOL_GREY_700 = 'coolGrey700',
  COOL_GREY_800 = 'coolGrey800',
  COOL_GREY_900 = 'coolGrey900',
}

export const baseColors: Record<Colors, string> = {
  [Colors.PURPLE]: '#5347D1',
  [Colors.PURPLE100]: '#F7F6FE',
  [Colors.PURPLE200]: '#EBE9FB',
  [Colors.PURPLE300]: '#D4D1F5',
  [Colors.PURPLE400]: '#BCB8EA',
  [Colors.PURPLE500]: '#8881DA',
  [Colors.PURPLE600]: '#6057C7',
  [Colors.PURPLE700]: '#483EB1',
  [Colors.PURPLE800]: '#2E2877',
  [Colors.PURPLE900]: '#1E1A4D',
  [Colors.YELLOW]: '#f2a556',
  [Colors.BLUE]: '#43c4eb',
  [Colors.RED]: '#f26956',
  [Colors.GREEN]: '#38e086',
  [Colors.PINK]: '#ff6584',

  [Colors.GREY500]: '#0a1b40',
  [Colors.GREY400]: '#4c5766',
  [Colors.GREY300]: '#98a3b3',
  [Colors.GREY200]: '#c4cbd4',
  [Colors.GREY200_2]: '#CFD6E2',
  [Colors.GREY100]: '#f2f7fa',
  [Colors.WHITE]: '#fff',
  [Colors.BLACK]: '#000',

  [Colors.MODAL]: '#FFE5D6',

  [Colors.COOL_GREY_100]: '#F8F9FC',
  [Colors.COOL_GREY_200]: '#F4F6FA',
  [Colors.COOL_GREY_300]: '#E0E4EB',
  [Colors.COOL_GREY_400]: '#B6BCC9',
  [Colors.COOL_GREY_500]: '#838995',
  [Colors.COOL_GREY_600]: '#5E636E',
  [Colors.COOL_GREY_700]: '#454A54',
  [Colors.COOL_GREY_800]: '#29303D',
  [Colors.COOL_GREY_900]: '#151C28',
}
