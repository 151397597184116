import { FunctionComponent, useEffect, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'
import { Checkbox } from 'rsuite'
import styled from 'styled-components'

import { Label } from 'components/Label'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { giveConsent } from 'store/survey/surveySlice'

import picture from '../../../assets/welcome.1x.png'
import sources from '../../../assets/welcome.png'
import sourcesWebp from '../../../assets/welcome.webp'
import { Layout } from '../../../layout'
import { RoundButton } from '../../../styled'
import { Message } from '../../../styled/message'

interface SurveyConsentWelcomeProps {
  onStart: () => void
}

const Consent = styled.div`
  h2 {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  p:not(:first-of-type) {
    font-size: 14px;
    line-height: 1.71;
  }

  .toc-checkbox {
    margin-top: 20px;
    margin-bottom: 48px;
  }

  .rs-btn {
    margin: initial;
  }
`

export const ConsentWelcome: FunctionComponent<SurveyConsentWelcomeProps> = ({
  onStart,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { tokenData, error, loading } = useAppSelector(
    (state: State) => ({
      tokenData: state.surveyReducer.tokenData,
      error: state.surveyReducer.error,
      loading: state.surveyReducer.loading,
    }),
    shallowEqual
  )
  const [consent, setConsent] = useState(false)
  const [updating, setUpdating] = useState(false)

  const onSubmit = () => {
    setUpdating(true)
    dispatch(giveConsent())
  }

  useEffect(() => {
    if (updating && !loading) {
      setUpdating(false)
      if (!error) {
        onStart()
      }
    }
  }, [error, loading, onStart, updating])

  return (
    <Layout.Sign picture={picture} sources={sources} sourcesWebp={sourcesWebp}>
      <Consent>
        <h2>{t('survey.consent.welcomeToFairHQ')}</h2>
        <Message type="consent">
          <strong>
            {t('survey.consent.weAreSoGlad', {
              companyName: tokenData?.companyName,
            })}
          </strong>
        </Message>
        <Message type="consent">
          <strong>{t('survey.consent.yourAnswerWillBe')}</strong>
          <br />
          <Trans
            i18nKey="survey.consent.inTheFollowingSurveyNew"
            components={[
              <a
                href={t('meta.privacyLink')}
                title={t('privacyPolicy')}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>,
              <a
                href={t('meta.surveyFAQLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                Frequently Asked Questions (FAQ)
              </a>,
            ]}
          />
        </Message>
        <Checkbox
          value="accepted"
          className="toc-checkbox"
          onChange={(value, checked) => setConsent(checked)}
        >
          <Label
            checked={consent}
            label={t('survey.consent.confirmAndAgree')}
            isCheckbox
          />
        </Checkbox>
        <RoundButton disabled={!consent || updating} onClick={onSubmit}>
          {t('survey.consent.start')}
        </RoundButton>
      </Consent>
    </Layout.Sign>
  )
}
