import { config } from '@fairhq/common'

export const getDefaultHeaders = () => {
  const defaultHeaders: HeadersInit = {
    'fairhq-account': localStorage.getItem(config.accountId) || '',
    'fairhq-company': localStorage.getItem(config.companyId) || '',
    'fairhq-version': localStorage.getItem(config.APIversion) || '',
    'fairhq-session': localStorage.getItem(config.sessionId) || '',
  }

  if (config.surveyUIEnabled) {
    // TypeScript is not happy with this - I'm not entirely clear why
    // It looks like it's not happy because fairhq-employee was not declared
    // on defaultHeaders at creation?
    // @ts-ignore
    defaultHeaders['fairhq-employee'] = localStorage.getItem(config.employeeId)
  }

  const token = localStorage.getItem(config.tokenKey)
  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`
  }

  return defaultHeaders
}
