import { FunctionComponent, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'
import { ButtonToolbar, Modal } from 'rsuite'
import styled from 'styled-components'

import { LinkButton, RoundButton } from '../../styled'

export const ConfirmModal = styled(Modal)`
  .rs-modal-body {
    padding: 48px 60px;
    text-align: center;
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.67;
      margin-bottom: 24px;
      margin-top: 0;
    }
    p {
      font-size: 16px;
      line-height: 1.87;
    }
  }
  .rs-modal-footer {
    .rs-btn-toolbar {
      display: flex;
      flex-flow: column;
      align-items: center;

      .rs-btn:last-of-type:not(:first-of-type) {
        margin-top: 36px;
        margin-left: 0;
      }
    }
  }
`

export const Confirm: FunctionComponent<{
  onCancel: () => void
  onSubmit: () => void
  visible: boolean
  cancelLabel?: string
  confirmLabel?: string
  danger?: boolean
  loading?: boolean
  onClose?: () => void
  title?: ReactNode
  children?: ReactNode
}> = ({
  children,
  cancelLabel,
  confirmLabel,
  danger,
  loading,
  onCancel,
  onClose,
  onSubmit,
  visible,
  title,
}) => {
  const { t } = useTranslation()
  return (
    <ConfirmModal
      backdrop="static"
      show={visible}
      onHide={onClose || onCancel}
      size="md"
    >
      <Modal.Header onHide={onCancel} />
      <Modal.Body>
        {title}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <RoundButton
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            color={danger ? 'red' : undefined}
          >
            {confirmLabel || t('confirm.yes')}
          </RoundButton>
          <LinkButton onClick={onCancel}>
            {t(cancelLabel || 'confirm.cancel')}
          </LinkButton>
        </ButtonToolbar>
      </Modal.Footer>
    </ConfirmModal>
  )
}
