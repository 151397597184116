import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { HeaderTitle } from 'components/header-title'
import { useAreaProgress } from 'modules/areas/useAreaProgress'

export const Header: FunctionComponent = () => {
  const { t } = useTranslation()
  const { skipped, completed, total } = useAreaProgress()

  return (
    <HeaderTitle
      confirmMessage={t('confirm.areYouSure')}
      title={t('employeeSurvey')}
      skipped={skipped}
      completed={completed}
      total={total}
    />
  )
}
