import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { handleErrorState } from '../helpers/handleErrorState'
import { isClearAccount } from '../helpers/isClearAccount'
import { isClearAll } from '../helpers/isClearAll'
import { isFulfilled } from '../helpers/isFulfilled'
import { isPending } from '../helpers/isPending'
import { isRejected } from '../helpers/isRejected'
import { questionSessionActions } from '../questionSession/questionSessionSlice'

import { assessmentApi } from './assessmentApi'
import { AssessmentState, Assessment } from './types'

const clear = createAction('assessment/clear')

export const save = createAsyncThunk(
  'assessment/save',
  async (assessment: Partial<Assessment>, { dispatch }) => {
    const result = await waitForResponse({
      callback: () => assessmentApi.save(assessment),
    })
    dispatch(questionSessionActions.addAssessment(result))
    dispatch(questionSessionActions.next())

    return result
  }
)
export const update = createAsyncThunk(
  'assessment/update',
  async (
    {
      id,
      assessment,
    }: {
      id: string
      assessment: Partial<Assessment>
    },
    { dispatch }
  ) => {
    const result = await waitForResponse({
      callback: () => assessmentApi.update(id, assessment),
    })
    dispatch(questionSessionActions.addAssessment(result))
    dispatch(questionSessionActions.next())

    return result
  }
)

const initialState: Partial<AssessmentState> = { loading: false }

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(update.pending, state => {
        state.saving = true
        state.assessment = undefined
      })
      .addCase(update.fulfilled, (state, action) => {
        state.saving = false
        state.assessment = action.payload
      })
      .addCase(save.pending, state => {
        state.saving = true
        state.assessment = undefined
      })
      .addCase(save.fulfilled, (state, action) => {
        state.saving = false
        state.assessment = action.payload
      })
      .addMatcher(isPending('assessment'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isFulfilled('assessment'), state => {
        state.loading = false
      })
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isRejected('assessment'), handleErrorState)
  },
})

export const { reducer: assessmentReducer } = assessmentSlice
