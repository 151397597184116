import { FunctionComponent } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Checkbox, CheckboxGroup, Container, Radio, RadioGroup } from 'rsuite'
import styled from 'styled-components'

import { Label } from 'components/Label'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionOption } from 'store/questionSession/types'
import { State } from 'store/state'

const ChoiceContainer = styled(Container)`
  .rs-radio,
  .rs-checkbox {
    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 12px;
    }

    label {
      text-align: left;
      white-space: initial;
    }
  }
`

interface SingleOrMultipleChoiceProps {
  multiple?: boolean
}

export const SingleOrMultipleChoice: FunctionComponent<
  SingleOrMultipleChoiceProps
> = ({ multiple }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { question, selectedOption, selectedOptions, other } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      other: state.questionSessionReducer.other,
      selectedOption: state.questionSessionReducer.selectedOption,
      selectedOptions: state.questionSessionReducer.selectedOptions ?? [],
    }),
    shallowEqual
  )
  const { options } = question ?? {}
  const Input = multiple ? Checkbox : Radio
  const isOtherVisible = options?.find(
    ({ id, isOther }) =>
      (multiple
        ? (selectedOptions || [])?.includes?.(id)
        : id === selectedOption) && isOther
  )

  const toOption = ({ id, code, title }: QuestionOption) => (
    <Input key={code} value={id}>
      <Label
        label={title}
        checked={
          multiple
            ? (selectedOptions || [])?.includes?.(id)
            : id === selectedOption
        }
        isCheckbox={multiple}
      />
    </Input>
  )

  const onChangeMultiple = (values: number[]) => {
    const none = options?.find(({ isNone }) => isNone)
    const otherOption = options?.find(({ isOther }) => isOther)
    const noneSelected = none ? values.find(id => id === none.id) : false
    const otherSelected = otherOption
      ? values.find(id => id === otherOption.id)
      : false
    if (noneSelected) {
      const newValues = []
      newValues.push(noneSelected)
      if (otherSelected) {
        newValues.push(otherSelected)
      }
      dispatch(questionSessionActions.setSelectedOptions(newValues))
    } else {
      dispatch(questionSessionActions.setSelectedOptions(values))
    }
  }

  return (
    <ChoiceContainer>
      {multiple ? (
        <CheckboxGroup onChange={onChangeMultiple} value={selectedOptions}>
          {_.sortBy(options, ['priority'])?.map(toOption)}
        </CheckboxGroup>
      ) : (
        <RadioGroup
          onChange={value => {
            dispatch(questionSessionActions.setSelectedOption(value))
          }}
          value={selectedOption}
          appearance="picker"
        >
          {_.sortBy(options, ['priority'])?.map(toOption)}
        </RadioGroup>
      )}
      {isOtherVisible ? (
        <textarea
          style={{ marginTop: '12px' }}
          onChange={e =>
            dispatch(questionSessionActions.setOther(e.target.value))
          }
          placeholder={t('other.writeItHere')}
          rows={5}
          value={other}
        />
      ) : undefined}
    </ChoiceContainer>
  )
}
