import React, { ReactNode, FunctionComponent } from 'react'

import { Container } from 'rsuite'

import { CheckboxChecked } from 'components/svgs/icons/CheckboxChecked'
import { CheckboxEmpty } from 'components/svgs/icons/CheckboxEmpty'
import { RadioChecked } from 'components/svgs/icons/RadioChecked'
import { RadioEmpty } from 'components/svgs/icons/RadioEmpty'

interface LabelProps {
  checked: boolean
  isCheckbox?: boolean
  label?: ReactNode
}

export const Label: FunctionComponent<LabelProps> = ({
  label,
  checked,
  isCheckbox,
}) => {
  const CheckedIcon = isCheckbox ? CheckboxChecked : RadioChecked
  const EmptyIcon = isCheckbox ? CheckboxEmpty : RadioEmpty

  return (
    <Container>
      {checked ? <CheckedIcon /> : <EmptyIcon />}
      {label ? <span>{label}</span> : undefined}
    </Container>
  )
}
