import Bugsnag from '@bugsnag/js'
import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { config } from '@fairhq/common'

const keysSentToSentry = new Set()
const interpolationSent = new Set()
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${config.translationApiKey}`

const sendNotification = (message: string) => {
  Bugsnag.notify({ name: 'i18n', message })
}

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: { loadPath },
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    keySeparator: false,
    defaultNS: 'default',
    ns: ['default'],
    supportedLngs: ['en', 'fr'],
    missingInterpolationHandler(
      text: string,
      value: any,
      { lng }: InitOptions
    ) {
      const message = `Missing interpolation "${value}" into "${text}" (${lng})`

      if (!interpolationSent.has(text)) {
        sendNotification(message)
        interpolationSent.add(text)
      }
    },
    // eslint-disable-next-line unused-imports/no-unused-vars
    parseMissingKeyHandler(key, ...rest) {
      const message = `Missing translation key: ${key} (${i18n.language})`

      if (!keysSentToSentry.has(key)) {
        sendNotification(message)
        keysSentToSentry.add(key)
      }

      return key
    },
  })

export default i18n
