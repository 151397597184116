import React, { FunctionComponent } from 'react'

export const CheckboxEmpty: FunctionComponent = ({
  className,
}: {
  className?: string
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" stroke="#C4CBD4" />
  </svg>
)
