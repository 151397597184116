import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { Container } from 'rsuite'
import styled from 'styled-components'

import { Message } from '../../../styled/message'

import picture from '../assets/finish.1x.png'
import sources from '../assets/finish.png'
import sourcesWebp from '../assets/finish.webp'

const WelcomeContainer = styled(Container)`
  max-width: ${({ theme }) => theme.maxWidth};
  flex-direction: row;
  align-items: center;
  margin: 66px auto auto;
  flex: 1;

  div {
    max-width: 480px;
    margin-right: 88px;

    h2 {
      font-size: 30px;
      line-height: 1.4;
      margin-bottom: 18px;
    }

    h3 {
      font-family: Poppins, sans-serif;
      font-weight: bold;
      font-size: 21px;
      line-height: 1.71;
      margin-bottom: 6px;
    }

    ${Message} {
      display: block;

      &:not(:last-of-type) {
        margin-bottom: 48px;
      }
    }
  }
`

export const Welcome = () => {
  const { t } = useTranslation()
  return (
    <WelcomeContainer>
      <div>
        <h2>{t('thankYou')}</h2>
        <h3>{t('survey.weHearYou')}</h3>
        <Message>{t('survey.thankYouForSharing')}</Message>
        <Message type="indication">
          <Trans
            i18nKey="survey.yourHRTeam"
            components={[
              <a
                href="https://www.fairhq.co/blog"
                title={t('survey.yourHRTeam')}
                target="_blank"
                rel="noopener noreferrer"
              >
                xxx
              </a>,
            ]}
          />
        </Message>
      </div>
      <picture>
        <picture>
          <source srcSet={sourcesWebp} type="image/webp" />
          <source srcSet={sources} />
          <img src={picture} alt="Fair HQ" />
        </picture>
      </picture>
    </WelcomeContainer>
  )
}
