import { useCallback } from 'react'

import _ from 'lodash'
import { shallowEqual } from 'react-redux'

import { update, save } from 'store/assessment/assessmentSlice'
import { Assessment } from 'store/assessment/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionOption } from 'store/questionSession/types'
import { State } from 'store/state'

import { scrollTop } from 'utils/scrollTop'

import { buildOptions } from './helpers/buildOptions'
import { shouldSaveAssessment } from './helpers/shouldSaveAssessment'

export const useSaveAnswer = () => {
  const dispatch = useAppDispatch()
  const { currentAssessment, question } = useAppSelector(
    (state: State) => ({
      currentAssessment: state.questionSessionReducer.assessment,
      question: state.questionSessionReducer.question,
    }),
    shallowEqual
  )
  const saveAssessment = useCallback(
    (assessmentToSave: Partial<Assessment>, id?: string) => {
      if (id && !_.isEqual(currentAssessment, assessmentToSave)) {
        dispatch(update({ id, assessment: assessmentToSave }))
      } else {
        dispatch(save(assessmentToSave))
      }
    },
    [currentAssessment, dispatch]
  )
  const onSubmitAssessment = ({
    selectedOption,
    getAssessmentToSave,
    skip,
    value,
    other,
  }: {
    selectedOption: number | number[] | undefined
    value: string
    other: string
    getAssessmentToSave: (
      options: any[],
      totalScore: number
    ) => Partial<Assessment>
    skip: boolean
  }) => {
    const options = buildOptions(
      (question?.options || []) as QuestionOption[],
      selectedOption
    )
    const totalScore = options
      .map(({ score }) => +score)
      .reduce((total, score) => total + score, 0)

    const assessmentToSave = getAssessmentToSave(options, totalScore)

    if (
      shouldSaveAssessment({
        assessment: currentAssessment,
        assessmentToSave,
      }) ||
      value ||
      other ||
      (skip && !currentAssessment?.skipped)
    ) {
      saveAssessment(assessmentToSave, currentAssessment?.id)
    } else {
      dispatch(questionSessionActions.next())
      scrollTop()
    }
  }

  return { onSubmitAssessment }
}
