import { FunctionComponent, useEffect, useState } from 'react'

import { usePrevious } from 'react-use'
import styled, { css } from 'styled-components'

import { ProgressContainer } from '../../styled'

const ProgressBar = styled.div<{
  progress: number
  hasError?: boolean
  orange?: boolean
  done?: boolean
  mode?: 'local' | 'sub' | 'title' | 'header'
}>`
  background: ${({ theme, progress, hasError, orange, mode, done }) =>
    orange && (mode === 'header' || mode === 'title')
      ? theme.colors.yellow
      : (hasError && theme.colors.red) ||
        (((!orange && progress === 100) || done) && theme.colors.green) ||
        (orange && 'transparent') ||
        ((mode === 'header' || mode === 'title') &&
          progress > 0 &&
          theme.colors.primary) ||
        theme.colors.grey200};
  height: 6px;
  transition: width 1s;
  width: 0;
  ${({ progress }) =>
    progress &&
    css`
      width: ${progress}%;
    `}
`

interface ProgressProps {
  progress: number
  hasError?: boolean
  done?: boolean
  mode?: 'local' | 'sub' | 'title' | 'header'
}

const Progress: FunctionComponent<ProgressProps> = ({
  hasError,
  mode,
  done,
  progress,
}) => {
  const [percent, setPercent] = useState(0)
  const previousPercent = usePrevious(progress)

  useEffect(() => {
    if (progress && previousPercent !== progress) {
      setPercent(progress)
    }
  }, [previousPercent, progress])

  return (
    <ProgressContainer>
      <ProgressBar
        done={done}
        progress={percent}
        hasError={hasError}
        mode={mode}
      />
    </ProgressContainer>
  )
}

export default Progress
