import { Assessment } from 'store/assessment/types'

export interface QuestionOption {
  [key: string]: any
  id: number
  airtableId: string
  code: string
  title: string
  score: number
  priority: number
  analysis: number
  max: number
  isOther: boolean
  isNone: boolean
}

export interface Area {
  id: number
  title: string
  description: string
  airtable_id: string
  code: string
  type: string
  area_airtable_id: string
  priority: number
  sources: string[]
  [key: string]: any
}

export enum QuestionType {
  MULTI = 'multi',
  DROPDOWM = 'dropdown',
  SEMANTIC = 'semantic',
  SCALE_TICK = 'scaleTick',
  SCALE_10 = 'scale10',
  TEXT_NUMBER = 'textNumber',
  BINARY = 'binary',
  SINGLE = 'single',
}
interface Part {
  code: string
  [key: string]: any
}

export interface Question {
  id: number
  type: QuestionType
  options: QuestionOption[]
  title: string
  description: string
  areaAirtableId: string
  airtableId: string
  code: string
  priority: number
  group: 'employee' | 'company'
  linkedOptions: QuestionOption[]
  linkedOptionsId: string[]
  linkedQuestionsId: string[]
  part: Part
  [key: string]: any
}

export interface QuestionSessionState {
  currentIndex: number
  currentAllIndex: number
  countAnswer: number
  hasNext: boolean
  questions: Question[]
  assessment?: Assessment
  question?: Question
  savedAssessments?: Assessment[]
  other: string
  value: string
  selectedOption?: number
  selectedOptions: number[]
}
