import React, { ReactNode, FunctionComponent } from 'react'

import _ from 'lodash'

import { shallowEqual } from 'react-redux'
import { Container } from 'rsuite'

import styled from 'styled-components'

import { AreaFooter } from 'modules/areas/AreaFooter'
import { useAppSelector } from 'store/hooks'
import { QuestionType } from 'store/questionSession/types'
import { State } from 'store/state'

import { QuestionCard } from './components/QuestionCard'

export const QuestionContainer = styled(Container)`
  position: relative;
`
interface QuestionsProps {
  header: ReactNode
  welcome?: ReactNode
  onSubmit: ({
    selectedOption,
    value,
    other,
  }: {
    selectedOption?: number | number[]
    value: string
    other: string
  }) => void
  onPrevious: () => void
  started: boolean
  loadingNext: boolean
  onSkip?: () => void
}

export const Questions: FunctionComponent<QuestionsProps> = ({
  onSkip,
  onPrevious,
  loadingNext,
  started,
  header,
  welcome,
  onSubmit,
}) => {
  const {
    selectedOptions,
    selectedOption,
    value,
    other,
    question,
    currentIndex,
    isLast,
    skipped,
  } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      value: state.questionSessionReducer.value,
      other: state.questionSessionReducer.other,
      selectedOptions: state.questionSessionReducer.selectedOptions,
      selectedOption: state.questionSessionReducer.selectedOption,
      currentIndex: state.questionSessionReducer.currentIndex,
      isLast:
        state.questionSessionReducer.currentAllIndex ===
        state.questionSessionReducer.questions.length - 1,
      skipped: state.questionSessionReducer.assessment?.skipped,
    }),
    shallowEqual
  )

  const handleSubmit = () => {
    onSubmit({
      selectedOption:
        question?.type === QuestionType.MULTI
          ? selectedOptions
          : selectedOption,
      value,
      other,
    })
  }

  return (
    <Container style={{ minHeight: '100%' }}>
      {header}
      {started ? (
        <QuestionContainer>
          <QuestionCard />
          <AreaFooter
            disabledNext={
              !selectedOption && !value && _.isEmpty(selectedOptions)
            }
            disabledPrevious={currentIndex === 0}
            loadingNext={loadingNext}
            onNext={handleSubmit}
            onPrevious={onPrevious}
            onSkip={onSkip}
            hasNext={!isLast}
            skipped={skipped}
          />
        </QuestionContainer>
      ) : (
        welcome
      )}
    </Container>
  )
}
