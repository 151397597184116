import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/advancedFormat'))
dayjs.extend(require('dayjs/plugin/localizedFormat'))
dayjs.extend(require('dayjs/plugin/relativeTime'))

declare module 'dayjs' {
  interface Dayjs {
    fromNow(withoutSuffix?: boolean): string
  }
}

export const toDate = (language: string, date?: string | number) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).locale(language)
  }
  return dayjs(date).locale(language)
}
