import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Container } from 'rsuite'

import BetaBadge from 'components/BetaBadge'

import { Confirm } from 'components/confirm'

import Progress from 'components/progress'

import { Title, LinkButton } from '../../styled'
import { Message } from '../../styled/message'

import { ReactComponent as CloseIcon } from './assets/close.svg'
import { ReactComponent as FairHQIcon } from './assets/fairhq.svg'

import { HeaderTitleContainer } from './styled'

interface HeaderTitleProps {
  confirmMessage: string
  title: string
  skipped?: number
  completed?: number
  onClose?: () => void
  total?: number
}

export const HeaderTitle: FunctionComponent<HeaderTitleProps> = ({
  confirmMessage,
  onClose,
  title,
  skipped = 0, // eslint-disable-line unused-imports/no-unused-vars
  completed = 0,
  total = 0,
}) => {
  const { t } = useTranslation()
  const [confirmVisible, setConfirmVisible] = useState(false)
  return (
    <HeaderTitleContainer>
      {onClose ? (
        <Confirm
          title={<h3>{t('confirm.wannaTakeABreak')}</h3>}
          confirmLabel={t('confirm.leaveAndSave')}
          cancelLabel={t('confirm.continue')}
          onCancel={() => setConfirmVisible(false)}
          onSubmit={() => {
            onClose && onClose()
            setConfirmVisible(false)
          }}
          visible={confirmVisible}
        >
          <Message>{confirmMessage}</Message>
        </Confirm>
      ) : undefined}
      <Title>
        <FairHQIcon />
        <BetaBadge />
      </Title>
      <Container>
        <h2>{title}</h2>
      </Container>
      {onClose ? (
        <LinkButton onClick={() => setConfirmVisible(true)}>
          <span>{t('header.close')}</span>
          <CloseIcon />
        </LinkButton>
      ) : undefined}
      <Progress
        progress={(completed && total && (completed * 100) / total) || 0}
        mode="header"
      />
    </HeaderTitleContainer>
  )
}
