import { FunctionComponent } from 'react'

import { NavLink } from 'react-router-dom'

import { defaultImage } from 'defaultImage'

import { ReactComponent as LogoIcon } from './assets/logo.svg'
import {
  SignContainer,
  SignLayoutContainer,
  ImageContainer,
  Content,
} from './styled/sign'

const Sign: FunctionComponent<{
  notFound?: boolean
  invalidToken?: boolean
  picture?: string
  sources?: string
  sourcesWebp?: string
  children?: React.ReactNode
}> = ({ children, invalidToken, notFound, picture, sources, sourcesWebp }) => (
  <SignLayoutContainer>
    <SignContainer>
      <Content>
        <NavLink to="/" style={{ marginBottom: '80px' }}>
          <LogoIcon />
        </NavLink>
        {children}
      </Content>
    </SignContainer>
    <ImageContainer notFound={notFound} invalidToken={invalidToken}>
      <picture>
        <source srcSet={sourcesWebp || defaultImage.webp} type="image/webp" />
        <source srcSet={sources || defaultImage.png} />
        <img src={picture || defaultImage.src} alt="Fair HQ" />
      </picture>
    </ImageContainer>
  </SignLayoutContainer>
)

export default Sign
