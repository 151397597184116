import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import { Container, SelectPicker } from 'rsuite'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { State } from 'store/state'

export const Dropdown = () => {
  const dispatch = useAppDispatch()
  const { question, selectedOption } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      selectedOption: state.questionSessionReducer.selectedOption,
    }),
    shallowEqual
  )
  const { title, options } = question ?? {}

  return (
    <Container>
      <SelectPicker
        onChange={value => {
          dispatch(questionSessionActions.setSelectedOption(value))
        }}
        value={selectedOption}
        data={
          _.sortBy(options, ['priority'])?.map(option => ({
            value: option.id,
            label: option.title,
          })) ?? []
        }
        placeholder={title}
        searchable={false}
        maxHeight={150}
        size="lg"
      />
    </Container>
  )
}
