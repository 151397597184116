import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import {
  surveyActions,
  getInvitation,
  getQuestions,
  getAssessments,
} from 'store/survey/surveySlice'

import { useQuery } from './useQuery'

export const useToken = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const token = useQuery().get('token')
  const { tokenData, questions } = useAppSelector((state: State) => ({
    tokenData: state.surveyReducer.tokenData,
    questions: state.questionSessionReducer.questions ?? [],
  }))
  const [errorMessage, setErrorMessage] = useState('')

  useMount(() => {
    if (token) {
      dispatch(surveyActions.setToken(token))
    } else {
      setErrorMessage(t('survey.error.token.missing'))
    }
  })

  useEffect(() => {
    if (tokenData?.status === 'invalid') {
      setErrorMessage(t('survey.error.token.unknown'))
    }
  }, [t, tokenData?.status])

  useEffect(() => {
    if (tokenData && tokenData?.status !== 'valid') {
      setErrorMessage(t(`survey.error.token.${tokenData?.status}`))
    }
  }, [t, tokenData])

  useEffect(() => {
    if (tokenData?.status === 'valid') {
      dispatch(getInvitation())
      dispatch(getQuestions())
      dispatch(getAssessments())
    }
  }, [dispatch, tokenData])

  useEffect(() => {
    if (questions?.length > 0) {
      dispatch(getAssessments())
    }
  }, [dispatch, questions?.length])

  return { errorMessage }
}
