import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { LinkButton, RoundButton } from '../../styled'

import { ReactComponent as NextIcon } from './next.svg'
import { ReactComponent as PreviousIcon } from './previous.svg'

const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-flow: row wrap;
  max-width: ${({ theme }) => theme.maxWidth2};
  width: 100%;
  height: 100px;
  min-height: 100px;
  margin: auto !important;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};

  .rs-btn {
    font-size: 14px;
  }
`

const NextSection = styled.div`
  .rs-btn:first-of-type {
    margin-right: 18px;
  }
`

const PreviousSection = styled.div`
  display: flex;
  align-content: center;

  .rs-btn:first-of-type {
    margin-right: 36px;

    svg {
      margin-right: 12px;
    }
  }
`

interface AreaFooterProps {
  disabledNext?: boolean
  disabledPrevious?: boolean
  loadingNext?: boolean
  loadingSkipDocument?: boolean
  onNext?: () => void
  onPrevious?: () => void
  onSkip?: () => void
  onSkipDocument?: () => void
  hasNext?: boolean
  skipped?: boolean
}

export const AreaFooter: FunctionComponent<AreaFooterProps> = ({
  disabledNext,
  disabledPrevious,
  loadingNext,
  loadingSkipDocument,
  onNext,
  onPrevious,
  onSkip,
  onSkipDocument,
  hasNext,
  skipped,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <PreviousSection>
        {onPrevious ? (
          <LinkButton onClick={onPrevious} disabled={disabledPrevious}>
            <PreviousIcon />
            <span>{t('areas.label.previous')}</span>
          </LinkButton>
        ) : undefined}
        {onSkip ? (
          <LinkButton onClick={onSkip}>
            {skipped
              ? t('areas.label.skipAgain')
              : t('areas.label.skipQuestion')}
          </LinkButton>
        ) : undefined}
      </PreviousSection>
      <NextSection>
        {onSkipDocument ? (
          <RoundButton
            appearance="ghost"
            onClick={onSkipDocument}
            disabled={loadingSkipDocument}
            loading={loadingSkipDocument}
          >
            {t('areas.label.skipDocument')}
          </RoundButton>
        ) : undefined}
        {onNext ? (
          <RoundButton
            onClick={onNext}
            disabled={disabledNext || loadingNext}
            loading={loadingNext}
          >
            <span>
              {hasNext ? t('areas.label.next') : t('areas.label.finish')}
            </span>
            {hasNext ? (
              <NextIcon
                style={{
                  marginLeft: '60px',
                  marginRight: '-30px',
                  opacity: 0.5,
                }}
              />
            ) : undefined}
          </RoundButton>
        ) : undefined}
      </NextSection>
    </Container>
  )
}
