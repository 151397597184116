import React, { useEffect, useState, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import Loading from 'components/loading'
import { Questions } from 'components/questions/Questions'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionType } from 'store/questionSession/types'
import { State } from 'store/state'
import { updateSurvey } from 'store/survey/surveySlice'

import { toDate } from 'utils/toDate'

import { ConsentWelcome } from './components/ConsentWelcome'
import { Header } from './components/Header'
import { Welcome } from './components/Welcome'

import { useSaveAnswer } from './hooks/useSaveAnswer'

export const Survey = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const { question, hasNext, survey, loading, tokenData, invitation } =
    useAppSelector(
      (state: State) => ({
        invitation: state.surveyReducer.invitation,
        survey: state.surveyReducer.survey,
        tokenData: state.surveyReducer.tokenData,
        question: state.questionSessionReducer.question,
        hasNext: state.questionSessionReducer.hasNext,
        loading: state.surveyReducer.loading,
      }),
      shallowEqual
    )
  const [completed, setCompleted] = useState(false)
  const [started, setStarted] = useState(false)
  const { onSubmitAssessment } = useSaveAnswer()

  useEffect(() => {
    if (!hasNext) {
      dispatch(
        updateSurvey({
          survey: {
            companyId: tokenData?.companyId,
            accountId: tokenData?.accountId,
            employeeId: tokenData?.employeeId,
            sessionId: tokenData?.sessionId,
            completed: true,
            completedAt: toDate(i18n.language).format(),
          },
        })
      )
      setTimeout(() => {
        setCompleted(true)
      }, 1000)
    }
  }, [dispatch, hasNext, i18n.language, tokenData])

  useEffect(() => {
    if (survey?.completed) {
      setCompleted(true)
    }
  }, [survey])

  const onSubmit = ({
    selectedOption,
    other = '',
    value = '',
    skip = false,
  }: {
    selectedOption?: number | number[]
    value?: string
    other?: string
    skip?: boolean
  }) => {
    onSubmitAssessment({
      selectedOption,
      value,
      other,
      skip,
      getAssessmentToSave: (options, totalScore) =>
        ({
          areaId: question?.area?.id,
          questionId: question?.id,
          score: totalScore,
          assessmentOptions: skip
            ? []
            : options?.map(option => ({
                optionId: +option.id,
                score: option.score,
                analysis: option.analysis,
              })),
          sessionId: tokenData?.sessionId,
          companyId: tokenData?.companyId,
          accountId: tokenData?.accountId,
          surveyId: tokenData?.surveyId,
          skipped: skip,
          value:
            skip && question?.type === QuestionType.TEXT_NUMBER ? '0' : value,
          other,
        } as any),
    })
  }

  const onStart = useCallback(() => {
    setStarted(true)
  }, [])

  useEffect(() => {
    if (invitation?.consent) {
      onStart()
    }
  }, [invitation, onStart])

  if (loading || (started && !question) || !invitation) {
    return <Loading backdrop vertical content={t('loading')} />
  }

  if (!started) {
    return <ConsentWelcome onStart={() => onStart()} />
  }

  return (
    <Questions
      header={<Header />}
      welcome={<Welcome />}
      loadingNext={!!loading}
      onSubmit={onSubmit}
      onPrevious={() => dispatch(questionSessionActions.previous())}
      onSkip={question?.required ? undefined : () => onSubmit({ skip: true })}
      started={started && !completed}
    />
  )
}
