import styled from 'styled-components'

import { ProgressContainer, Subtitle, Title } from '../../../styled'

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding: 24px 0 30px;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 200;

  h2 {
    font-size: 24px;
    line-height: 1.67;
  }

  ${Subtitle} {
    line-height: 1.37;
  }

  ${Title} {
    display: flex;
    flex-direction: column;
    line-height: 2.25;
    padding: 0 20px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 32px;
      background: ${({ theme }) => theme.colors.grey200};
    }
  }

  .rs-container {
    padding-left: 30px;
    margin: 0;
  }

  .rs-btn {
    margin-right: 100px;
    padding: 0;

    svg {
      margin-left: 12px;
    }
  }

  ${ProgressContainer} {
    padding-left: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
