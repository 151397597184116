import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import { Container, Radio, RadioGroup, Row, Divider } from 'rsuite'
import styled from 'styled-components'

import { Label } from 'components/Label'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { QuestionOption } from 'store/questionSession/types'
import { State } from 'store/state'

const SemanticContainer = styled(Container)`
  display: flex;
  flex-flow: row;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  padding: 36px;
  justify-content: center;
  border-radius: 10px;
  height: 100px;

  .rs-row {
    margin-left: 42px;
    margin-right: 42px;
    position: relative;

    .rs-divider {
      position: absolute;
      left: 0;
      right: 0;
      top: -7px;
    }
  }

  .rs-radio-group {
    flex-flow: row;
    display: flex;

    .rs-radio {
      padding: 0;
      border: 0;
      height: auto;

      &:not(.rs-radio-checked) {
        .rs-container::before {
          content: ' ';
          background-color: white;
          max-width: 36px;
          max-height: 36px;
          min-width: 36px;
          min-height: 36px;
          border-radius: 36px;
          z-index: 100;
          position: absolute;
        }
      }

      .rs-radio-wrapper {
        display: none;
      }

      .rs-radio-checker .rs-container svg {
        min-width: 36px;
        min-height: 36px;
        z-index: 101;
      }
      label {
        min-height: auto;
        padding: 0;
      }
    }
  }
`

export const Semantic = () => {
  const dispatch = useAppDispatch()
  const { question, selectedOption } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      selectedOption: state.questionSessionReducer.selectedOption,
    }),
    shallowEqual
  )
  const { options } = question ?? {}
  const [firstOption] = options ?? []
  const lastOption = options?.length ? options[options.length - 1] : undefined
  const toRadioOption = ({ code, id }: QuestionOption) => (
    <Radio key={code} value={id}>
      <Label checked={id === selectedOption} />
    </Radio>
  )

  return (
    <SemanticContainer>
      <strong>{firstOption.title}</strong>
      <Row>
        <Divider />
        <RadioGroup
          onChange={value => {
            dispatch(questionSessionActions.setSelectedOption(value))
          }}
          value={selectedOption}
        >
          {_.sortBy(options, ['priority'])?.map(toRadioOption)}
        </RadioGroup>
      </Row>
      <strong>{lastOption?.title}</strong>
    </SemanticContainer>
  )
}
