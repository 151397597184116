import { Button, Container } from 'rsuite'
import styled, { css } from 'styled-components'

export const FormFooter = styled.div``

export const Title = styled.h1`
  position: relative;
  font-size: 16px;
`

export const RoundButton = styled(Button)`
  height: 66px;
  border-radius: 100px;
  font-weight: bold;
  padding: 21px 48px;
  line-height: 1.71;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  ${({ appearance, theme }) =>
    appearance === 'ghost'
      ? css`
          border-color: ${theme.colors.grey200};
          color: ${theme.colors.grey400};
        `
      : undefined};
`

RoundButton.defaultProps = {
  appearance: 'primary',
  type: 'button',
}

export const LinkButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.38;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey400};
`

LinkButton.defaultProps = {
  appearance: 'link',
  type: 'button',
}

export const ProgressContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.grey100};
`

export const Subtitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.grey300};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 30px;
`
