import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { Container, Heading } from 'theme-ui'

import { Layout } from 'layout'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { Message } from 'styled/message'

import picture from '../../assets/welcome.1x.png'
import sources from '../../assets/welcome.png'
import sourcesWebp from '../../assets/welcome.webp'

export const Maintenance = () => {
  const { tokenData } = useAppSelector(
    (state: State) => ({
      tokenData: state.surveyReducer.tokenData,
    }),
    shallowEqual
  )
  const { t } = useTranslation()

  return (
    <Layout.Sign picture={picture} sources={sources} sourcesWebp={sourcesWebp}>
      <Container>
        <Heading variant="h1" pb={4} color="grey500">
          {t('survey.maintenance.weWillBeBack')}
        </Heading>
        <Message>{t('survey.maintenance.makingChanges')}</Message>
        <Message>
          <Trans
            i18nKey="survey.maintenance.comeBackLater"
            values={{
              companyName: tokenData?.companyName ?? 'your company',
            }}
            components={[<strong />]}
          />
        </Message>
        <Message>{t('survey.maintenance.thankYou')}</Message>
      </Container>
    </Layout.Sign>
  )
}
