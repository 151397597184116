import { FunctionComponent, useEffect } from 'react'

import { Route, Routes as Switch, Navigate as Redirect } from 'react-router-dom'
import { useLocation } from 'react-use'
import { Content } from 'rsuite'

import { config } from '@fairhq/common'
import { Maintenance } from 'modules/maintenance/Maintenance'
import { NotFound } from 'modules/not-found/NotFound'
import { useToken } from 'modules/survey/hooks/useToken'
import { Survey } from 'modules/survey/Survey'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export const Routes: FunctionComponent = () => {
  const { surveyError } = useAppSelector((state: State) => ({
    surveyError: state.surveyReducer.error,
  }))

  const { errorMessage } = useToken()

  const { pathname, search } = useLocation()

  useEffect(() => {
    window?.gtag?.('config', config.analytics, { page_path: pathname })
  }, [pathname])

  const isUnderMaintenance = config.maintenanceMode === 'true'

  if (errorMessage) {
    return <NotFound invalidToken />
  }

  if (surveyError) {
    return <NotFound />
  }

  return (
    <Content>
      <Switch>
        <Route
          path="/"
          element={
            isUnderMaintenance ? (
              <Redirect to={{ pathname: '/maintenance', search }} />
            ) : (
              <Survey />
            )
          }
        />
        <Route
          path="/maintenance"
          element={
            isUnderMaintenance ? (
              <Maintenance />
            ) : (
              <Redirect to={{ pathname: '/', search }} />
            )
          }
        />
        <Route path="/bypass-maintenance" element={<Survey />} />
        <Route element={<NotFound />} />
      </Switch>
    </Content>
  )
}
