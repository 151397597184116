import React, { FunctionComponent } from 'react'

export const CheckboxChecked: FunctionComponent = ({
  className,
}: {
  className?: string
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM10.73 15.35L16.35 9.72999C16.5341 9.53684 16.5341 9.23314 16.35 9.03999L15.82 8.50999C15.6256 8.31942 15.3144 8.31942 15.12 8.50999L10.38 13.25L8.87997 11.76C8.78924 11.6633 8.66256 11.6085 8.52997 11.6085C8.39739 11.6085 8.27071 11.6633 8.17997 11.76L7.64997 12.29C7.55532 12.3839 7.50208 12.5117 7.50208 12.645C7.50208 12.7783 7.55532 12.9061 7.64997 13L10.03 15.35C10.1207 15.4467 10.2474 15.5015 10.38 15.5015C10.5126 15.5015 10.6392 15.4467 10.73 15.35Z"
      fill="#5347D1"
    />
  </svg>
)
