import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { waitForResponse, config } from '@fairhq/common'

import { questionSessionActions } from 'store/questionSession/questionSessionSlice'

import { decodeToken } from '../helpers/decodeToken'
import { handleErrorState } from '../helpers/handleErrorState'
import { isClearAccount } from '../helpers/isClearAccount'
import { isClearAll } from '../helpers/isClearAll'
import { isFulfilled } from '../helpers/isFulfilled'
import { isPending } from '../helpers/isPending'
import { isRejected } from '../helpers/isRejected'

import { surveyApi } from './surveyApi'
import { SurveyState, Survey } from './types'

const clear = createAction('employees/clear')

export const giveConsent = createAsyncThunk('survey/giveConsent', async () =>
  waitForResponse({
    callback: () => surveyApi.giveConsent(),
  })
)

export const getQuestions = createAsyncThunk(
  'survey/getQuestions',
  async (_, { dispatch }) => {
    const results = await waitForResponse({
      callback: () => surveyApi.getQuestions(),
    })

    dispatch(questionSessionActions.setQuestionsAfterGet(results))
    return results
  }
)

export const getAssessments = createAsyncThunk(
  'survey/getAssessments',
  async (_, { dispatch }) => {
    const results = await waitForResponse({
      callback: () => surveyApi.getAssessments(),
    })
    dispatch(questionSessionActions.saveAssessments(results))
    dispatch(questionSessionActions.init())
    return results
  }
)

export const updateSurvey = createAsyncThunk(
  'survey/update',
  async ({ survey }: { survey: Partial<Survey> }) =>
    waitForResponse({ callback: () => surveyApi.update(survey) })
)

export const getInvitation = createAsyncThunk(
  'survey/getInvitation',
  async () => waitForResponse({ callback: () => surveyApi.getInvitation() })
)

const initialState: Partial<SurveyState> = { loading: false }

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setToken(state, action) {
      const data = decodeToken(action.payload)
      state.tokenData = data
      localStorage.setItem(config.tokenKey, action.payload)
      localStorage.setItem(config.companyId, data?.companyId || '')
      localStorage.setItem(config.sessionId, data?.sessionId || '')
      localStorage.setItem(config.employeeId, data?.employeeId || '')
      localStorage.setItem(config.APIversion, data?.version || '')
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(giveConsent.fulfilled, (state, action) => {
        state.loading = false
        state.invitation = action.payload
      })
      .addCase(updateSurvey.fulfilled, (state, action) => {
        state.loading = false
        state.survey = action.payload
      })
      .addCase(getQuestions.pending, state => {
        state.loading = true
        state.questions = []
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.loading = false
        state.questions = action.payload
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.loading = false
        state.invitation = action.payload
      })
      .addCase(getAssessments.pending, state => {
        state.loading = true
        state.assessments = []
      })
      .addCase(getAssessments.fulfilled, (state, action) => {
        state.loading = false
        state.assessments = action.payload
      })
      .addMatcher(isFulfilled('survey'), state => {
        state.error = undefined
        state.loading = false
      })
      .addMatcher(isPending('survey'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('survey'), handleErrorState)
  },
})

export const { actions: surveyActions, reducer: surveyReducer } = surveySlice
