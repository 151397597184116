import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

dayjs.extend(require('dayjs/plugin/advancedFormat'))
dayjs.extend(require('dayjs/plugin/localizedFormat'))
dayjs.extend(require('dayjs/plugin/relativeTime'))

export function decodeToken(token = '') {
  if (!token) {
    return { status: 'invalid' }
  }

  try {
    const { expirationDate, ...restOfToken }: any = jwtDecode(token)
    if (dayjs(expirationDate).unix() > Math.floor(Date.now() / 1000)) {
      return { status: 'valid', ...restOfToken }
    }
    return { status: 'expired' }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  return { status: 'invalid' }
}
